import React from 'react';

import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout>
    <section id="one" className="main">
      <div className="container">
        <div className="content">
          <header className="major">
            <h2>Thank You</h2>
          </header>
          <section>
            <h4>Form Submitted</h4>
          </section>
          <p>
            You've just taken the first step to owning your own video platform.
            Thank you for your interest in EventsMadeLive! Expect an email from
            us as soon as we have more news to share.
          </p>
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
